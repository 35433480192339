import axios from 'axios';
import { createAuth } from "../Helpers";
import { apiRootUrl, ReducerMethods, TA } from "./configureStore";
import { Reducer } from "redux";

export const reducerName = 'freeTextState';

const FREETEXT_GET_REQUEST = 'FREETEXT_GET_REQUEST';
const FREETEXT_GET_RESPONSE = 'FREETEXT_GET_RESPONSE';

interface FreeTextState {
    text: [{id: number, content: string; size: number}];
}

const initialState: FreeTextState = {
    text: [{id: 0, content: 'loading...', size: 2}]
};

export const actionCreators = {
    get: (): TA => async (dispatch, getState) => {
        dispatch({ type: FREETEXT_GET_REQUEST });

        axios({
            method: 'GET',
            url: `${apiRootUrl}/api/freeText`,
            withCredentials: true,
            auth: createAuth(getState)
        }).then(response => {
            dispatch({ type: FREETEXT_GET_RESPONSE, data: response.data });
        }).catch(error => {
            console.log('ERROR FETCHING FREETEXT', error);
        });
    }
};

const reducerMethods: ReducerMethods<FreeTextState> = {
    FREETEXT_GET_RESPONSE: (state, action) => {
        return {
            ...state,
            text: action.data,

        }
    }
};

export const reducer: Reducer<FreeTextState> = (state, action) => {
    state = state || initialState;
    const method = reducerMethods[action.type];
    if (method) return method(state, action);
    return state;
};
