import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {actionCreators as documentActions} from '../store/DocumentStore';
import './Documents.css';
import {apiRootUrl, AppState} from "../store/configureStore";
import {DataDisplayDocument} from "../domain/DataDisplayDocument";
import folderIcon from '../images/documents.png';

interface Props{
    documentStore: any;
    documentActions: typeof documentActions;
}

const Documents = (props: Props) => {
    const documents = props.documentStore.documents as DataDisplayDocument[];
    const activeDocument = props.documentStore.activeDocument;
    const rootUrl = apiRootUrl;

    const sortedDocs = documents && documents.map(x => {return{...x, category: x.category || ''}}).sort((a,b) => a.category > b.category ? 1 : a.category < b.category ? -1 : 0);
    const categories = sortedDocs && sortedDocs.map(item => item.category).filter((item, i, ar) => ar.indexOf(item) === i);

    const [activeCat, setActiveCat] = useState((categories && categories[0]) || '');

    useEffect(() => {
        const filtered = sortedDocs && sortedDocs.filter(x => x.category === activeCat);
        if(filtered && filtered.length > 0){
            props.documentActions.setActive(filtered[0]);
        }
        //eslint-disable-next-line
    }, [activeCat]);

    console.log(activeCat, categories);

    return(
        <div className='documents'>
            <div className='documents-container'>
                {categories && categories.map(c =>
                    <div key={c}>
                        <h2 className={c === activeCat ? 'expanded' : ''} onClick={() => setActiveCat(c)}><img src={folderIcon} alt={'folder'} />{`${c || 'Ingen kategori'}`}<span className={'expand'}>{c === activeCat ? '-' : '+'}</span></h2>
                        {c === activeCat && sortedDocs && sortedDocs.filter(x => x.category === c).map((d: DataDisplayDocument) => {
                            return(
                                <div key={d.id} onClick={() => props.documentActions.setActive(d)} className={`document ${activeDocument && d.id === activeDocument.id ? 'active' : ''}`}>
                                    {d.name}
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
            {activeDocument &&
            <div className='preview'>
                <embed key={`${rootUrl}/${activeDocument.path}`} src={`${rootUrl}/${activeDocument.path}`} />
            </div>
            }
        </div>
    );
};

export default connect(
    (state: AppState) => ({
        documentStore: state.documentStore,
    }),
    dispatch => ({
        documentActions: bindActionCreators(documentActions, dispatch)
    })
)(Documents);