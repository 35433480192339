import React from 'react';
import './PlanForm.css';
import {actionCreators as plannedItemActions} from '../../store/PlannedItemStore';
import connect from 'react-redux/es/connect/connect';
import {bindActionCreators} from 'redux';
import Flatpickr from 'react-flatpickr';
import PropTypes from 'prop-types';
import moment from 'moment';
import {apiRootUrl} from "../../store/configureStore";

const PlanForm = props => {
    const person = props.state.personState.persons[props.personid];
    const colorCodes = props.state.plannedItemState.colorCodes.length > 0
        && props.state.plannedItemState.colorCodes.filter(x => !x.isAdminOnly);
    const visible = props.state.plannedItemState.showPlanForm;
    const plan = props.state.plannedItemState.plan;
    const endValid = props.state.plannedItemState.plan.endValid;
    const startValid = props.state.plannedItemState.plan.startValid;
    const valid = startValid && endValid;

    const submitForm = e =>{
        e.preventDefault();
        if(valid){
            props.plannedItemActions.createPlannedItem(
                props.personid,
                plan.colorCode || (colorCodes.length > 0 && colorCodes[0].id),
                moment(plan.startTime).format('YYYY-MM-DD HH:mm'),
                moment(plan.endTime).format('YYYY-MM-DD HH:mm')
            );
        }
    };

    const renderPerson = p =>{
        if(!p) return null;
        return(
            <div className="plan-profile">
                <div className="profile-text">
                    <h3>{p.name}</h3>
                    <p>{p.title}</p>
                 </div>
                 <img src={apiRootUrl + p.imagePath} alt="profile"/>
                 <div className="line"/>
            </div>
        )
    };

    const ColorCodes = () => {

        return colorCodes && colorCodes.map(cc => {
            return <option key={cc.id} value={cc.id} style={{color: cc.hexColor}}>{cc.name}</option>
        });
    };

    return(
        <div className='PlanForm'>
            <div className={visible ? '' : ' hidden'}>
               {renderPerson(person)}
                <form>
                    <input name="person" type="hidden" />
                    <div className="form-group dropdown">
                        <select onChange={e => props.plannedItemActions.setColorCode(e.target.value)} value={plan.colorCode || ''}>
                            {ColorCodes()}
                        </select>
                    </div>
                    <div className="form-group date">
                        <label>Start</label>
                        <Flatpickr
                            options={{time_24hr: true, enableTime: true, minuteIncrement: 30}}
                            onChange={d => props.plannedItemActions.setStartTime(d[0])} value={moment(plan.startTime).format('YYYY-MM-DD HH:mm')}
                            className={'date-picker-custom' + (startValid ? '' : ' invalid')}
                        />
                    </div>
                    <div className="form-group date">
                        <label>Slut</label>
                        <Flatpickr
                            options={{time_24hr: true, enableTime: true, minuteIncrement: 30}}
                            onChange={d => props.plannedItemActions.setEndTime(d[0])} value={moment(plan.endTime).format('YYYY-MM-DD HH:mm')}
                            className={'date-picker-custom' + (endValid ? '' : ' invalid')}
                        />
                    </div>
                    {props.state.plannedItemState.doubleBook &&
                        <div className='error'>Denne tidsperiode, eller dele af denne tidsperiode er desværre allerede booket.</div>
                    }
                    <button className="submit" onClick={submitForm}>Send</button>
                </form>
            </div>
            <div className={visible ? 'hidden' : ''}>
                <div className="plan-more" onClick={() => props.plannedItemActions.showPlanForm(true)}>Planlæg mere, tryk her</div>
            </div>
        </div>
    );
}

PlanForm.propTypes = {
    personid: PropTypes.string.isRequired
};

export default connect(
    state => ({state: state}),
    dispatch => ({
        plannedItemActions: bindActionCreators(plannedItemActions, dispatch)
    })
)(PlanForm);