import React from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../store/configureStore';

interface Props{
    text: [{id: number, content: string, size: number}]
}

const FreeText = (props: Props) => {
    return(
        <div className='free-text'>
            <div className='text' style={{fontSize: `${props.text[0].size}vh`}}>
                {props.text[0].content}
            </div>
        </div>
    )
}

export default connect(
    (state: AppState) => ({text: state.freeTextState.text})
)(FreeText);