import React from 'react';
import { connect } from 'react-redux';
import * as PersonStore from '../../store/PersonStore';
import moment from 'moment';
import Invitee from './Invitee';
import {apiRootUrl} from "../../store/configureStore";

const Event = props => {
    const event = props.event;
    const eventInvitations = event.invitations || [];
    const invited = eventInvitations.filter(ei => ei.status === 0);
    const accepted = eventInvitations.filter(ei => ei.status === 1);
    const rejected = eventInvitations.filter(ei => ei.status === 2);

    const renderInvitees = () => {
        return (
            <div className='overlay'>
                <div className='dialog' onClick={(e) => e.stopPropagation()}>
                    <div>
                        <div className='float'>
                            <h2>{event.name}</h2>
                            <p>{event.description && event.description}</p>
                            <div><b>Fra:</b> {moment(event.startTime).format('LLLL')}</div>
                            <div><b>Til:</b> {moment(event.endTime).format('LLLL')}</div>
                        </div>
                        <div className='float-right'>
                            <img className='event-image' src={`${apiRootUrl}/${event.imagePath}`} alt='event billede' />
                        </div>
                        <div className='clearfix' />
                    </div>
                    <div className='clearfix' />

                    <hr noshade/>
                    <h3>Inviterede</h3>
                    <div className='invitees'>
                        {invited.length < 1 && 'Ingen endnu....'}
                        {invited.map(ei => <Invitee eventInvitation={ei}/>) }
                    </div>
                    <h3>Kommer</h3>
                    <div className='invitees'>
                        {accepted.length < 1 && 'Ingen endnu....'}
                        {accepted.map(ei => <Invitee eventInvitation={ei}/>)}
                    </div>
                    <h3>Afslag</h3>
                    <div className='invitees'>
                        {rejected.length < 1 && 'Ingen endnu....'}
                        {rejected.map(ei => <Invitee eventInvitation={ei}/>)}
                    </div>
                </div>
            </div>
        )
    };

    return(
        <div className='event' onClick={props.onClick || null}>
            { event.imagePath && <img src={`${apiRootUrl}/${event.imagePath}`} alt='event billede'/> }
            <div className='event-name'><b>{event.name}</b></div>
            <div className='event-description'>{event.description && event.description}</div>
            <div className='count accept'>{accepted ? <b>{accepted.length}</b> : '0'}</div>
            <div className='count reject'>{rejected ? <b>{rejected.length}</b> : '0'}</div>
            <div className='count'>{invited ? <b>{invited.length}</b> : '0'}</div>
            {props.showInvitees && renderInvitees()}
        </div>
    )
};

export default connect(
    state => ({ personState: state[PersonStore.reducerName]}),
)(Event)