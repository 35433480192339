import {Action, applyMiddleware, combineReducers, compose, createStore, Reducer} from 'redux';
import thunk, {ThunkAction} from 'redux-thunk';
import * as TeamStore from "./TeamStore";
import * as PlannedItemStore from "./PlannedItemStore";
import * as PersonStore from "./PersonStore";
import * as SlideStore from "./SlideStore";
import * as CalendarStore from "./CalendarStore";
import * as GridStore from "./GridStore";
import * as DocumentStore from "./DocumentStore";
import * as AuthStore from "./AuthStore";
import * as ResourceStore from "./ResourceStore";
import * as EventStore from "./EventStore";
import * as DrNewsStore from "./DrNewsStore";
import * as FreeTextStore from './FreeTextStore';

const appReducer = combineReducers({
    teamState: TeamStore.reducer,
    plannedItemState: PlannedItemStore.reducer,
    personState: PersonStore.reducer,
    slideState: SlideStore.reducer,
    calendarState: CalendarStore.reducer,
    gridState: GridStore.reducer,
    documentStore: DocumentStore.reducer,
    auth: AuthStore.reducer,
    resourceState: ResourceStore.reducer,
    eventState: EventStore.reducer,
    drNewsState: DrNewsStore.reducer,
    freeTextState: FreeTextStore.reducer
});

export type AppState = ReturnType<typeof appReducer>

export type TA = ThunkAction<void, AppState, null, Action<string>>;

export type ReducerMethods<T> = {[key: string]: ReducerMethod<T>};
type ReducerMethod<T> = {(state: T, action: any): T};

export const apiRootUrl = "https://admin.datadisplay.dk";
//export const apiRootUrl = "http://localhost:50995";

export default function configureStore() {

    const middleware = [
        thunk
    ];

    // In development, use the browser's Redux dev tools extension if installed
    const enhancers = [];
    const isDevelopment = process.env.NODE_ENV === 'development';
    if (isDevelopment && typeof window !== 'undefined' && (window as any).__REDUX_DEVTOOLS_EXTENSION__) {
        enhancers.push((window as any).__REDUX_DEVTOOLS_EXTENSION__());
    }

    const rootReducer: Reducer<AppState, any> = (state, action) => {
        return appReducer(state, action);
    };

    const initialState = (window as any).initialReduxState;

    return createStore(
        rootReducer,
        initialState,
        compose(applyMiddleware(...middleware), ...enhancers)
    );
}
