import axios from 'axios';
import {createAuth} from "../Helpers";
import {apiRootUrl, ReducerMethods, TA} from "./configureStore";
import {SlideShow} from "../domain/SlideShow";
import {Reducer} from "redux";


const SLIDE_GET_RESPONSE = 'SLIDE_GET_RESPONSE';

export interface SlideState{
    slideShows: {[key: string]: SlideShow}
}

const initialState = {
    slideShows: {}
};

export const actionCreators = {
    getSlideShow: (id: number): TA => async(dispatch, getState) => {
        axios({
            method: 'GET',
            url: `${apiRootUrl}/api/slideShow/${id}`,
            withCredentials: true,
            auth: createAuth(getState)
        }).then(response => {
            const data = response.data;
            dispatch({type: SLIDE_GET_RESPONSE, data: data});
        }).catch(error =>{

        });
    }
};

const reducerMethods: ReducerMethods<SlideState> = {
    SLIDE_GET_RESPONSE: (state, action) => {
        return{
            ...state,
            slideShows:{
                ...state.slideShows,
                [action.data.id]: action.data,
            }
        }
    }
};

export const reducer: Reducer<SlideState, any> = (state, action) => {
    state = state || initialState;
    const method = reducerMethods[action.type];
    if (method) return method(state, action);
    return state;
};
