import React from 'react';
import connect from 'react-redux/es/connect/connect';
import {bindActionCreators} from 'redux';
import * as AuthStore from '../store/AuthStore';
import {actionCreators as routerActions} from '../store/RouterActions';

const Login = props => {
    return(
        <div className='login'>
            <form onSubmit={() => props.authActions.login()}>
                <label>OrganizationId</label>
                <input type='text' placeholder='Id' value={props.authState.orgId} onChange={e => props.authActions.setOrgId(e.target.value)} />
                <label>ApiKey</label>
                <input type='password' placeholder='Api-key' value={props.authState.apiKey} onChange={e => props.authActions.setApiKey(e.target.value)} />
                <input type='submit' className='btn' value='login'/>
            </form>
        </div>
    )
};

export default connect(
    state => ({authState: state[AuthStore.reducerName]}),
    dispatch => ({
        authActions: bindActionCreators(AuthStore.actionCreators, dispatch),
        routerActions: bindActionCreators(routerActions, dispatch)
    })
)(Login);