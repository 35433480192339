import React, {Component} from  'react';
import moment from 'moment';
import {EmptyDay} from './Day';
import {Person} from "../../domain/Person";
import {PlannedItem} from "../../domain/PlannedItem";
import {ColorCode} from "../../domain/ColorCode";
import {DDEvent} from '../../domain/DDEvent';

const _MS_PER_DAY = 1000 * 60 * 60 * 24;

interface Props{
    person: Person;
    plans: PlannedItem[];
    events: DDEvent[];
    days: moment.Moment[];
    highLightPersonId?: string;
    granularity: string;
    dayWidth: number;
    eventColor?: ColorCode;
    startTime: moment.Moment;
    totalTime: number;
    onClick: () => void;
    showOnlySickToday: boolean;
}

class CalendarLine extends Component<Props>{


    shouldComponentUpdate(nextProps: Readonly<Props>, nextState: Readonly<{}>, nextContext: any): boolean {
        const plansDifferent = JSON.stringify(this.props.plans) !== JSON.stringify(nextProps.plans);
        const eventsDifferent = JSON.stringify(this.props.events) !== JSON.stringify(nextProps.events);
        const daysDifferent = this.props.days[0] !== nextProps.days[0] ||
            this.props.days.length !== nextProps.days.length;
        const wasHighLighted = this.props.person.id === this.props.highLightPersonId;
        const isHighLighted = nextProps.person.id === nextProps.highLightPersonId;

        return plansDifferent || daysDifferent || (wasHighLighted !== isHighLighted) || eventsDifferent;
    }

    render(){
        const props = this.props;
        const person = props.person;
        const highLightId = props.highLightPersonId;
        const granularity = props.granularity;
        const days = props.days;
        const dayWidth = props.dayWidth;
        const plans = props.plans;
        const eventColor = props.eventColor;
        const events = props.events.filter(x => x.invitations.find(y => y.status === 1 && y.personId === person.id) != null);
        if(eventColor){
            events.forEach(e => {
                plans.push({startTime: e.startTime, endTime: e.endTime, colorCode: eventColor, id: 0, colorCodeId: eventColor.id, personId: person.id});
            });
        }

        const hourComponents = days.map((d,i) => {
            return plans.map((p,i) => {
                const startTime = moment(p.startTime).diff(props.startTime, 'hour') * dayWidth;
                const duration = moment(p.endTime).diff(p.startTime, 'hour');
                if(duration > 0){
                    return <div
                        key={i}
                        className='plan'
                        style={{
                            left: `${startTime}%`,
                            backgroundColor: p.colorCode.hexColor,
                            width: `${dayWidth*duration}%`
                        }}
                    />
                }
                return null;
            });
        });

        const dayComponents = () => {
            const start = props.startTime;
            return plans.map((p,i) => {
                let planStart = moment(p.startTime).startOf("day");
                let planEnd = moment(p.endTime).endOf("day");
                const isSick = p.colorCode.systemName === "sick";
                if(isSick && props.showOnlySickToday){
                    const startOfDay = moment().startOf("day");
                    const endOfDay = moment().endOf("day");
                    planEnd = planEnd.isAfter(endOfDay) ? endOfDay : planEnd;
                    planStart = planStart.isBefore(startOfDay) ? startOfDay : planStart;
                }

                const startTime = Math.ceil(planStart.diff(start, "milliseconds") / _MS_PER_DAY) * dayWidth;
                const duration = Math.ceil(planEnd.diff(planStart, "milliseconds") / _MS_PER_DAY);
                const isEvent = p.colorCode === props.eventColor;

                if(duration > 0){
                    return <div
                        key={i}
                        className={`plan ${isEvent ? 'event' : ''}`}
                        style={{
                            left: `${startTime}%`,
                            backgroundColor: p.colorCode.hexColor,
                            width: `${dayWidth*duration}%`
                        }}
                    />
                }
                return null;
            });
        };

        const grid = days.map((d,i) => {
            return <EmptyDay key={i} size={dayWidth} highlight={person.id === highLightId}/>;
        });


        return (
            <div className="row" onClick={props.onClick || undefined}>
                <div className={`name${person.leader ? ' leader' : ''}`}>{person.name}</div>
                <div className='plans'>
                    {granularity === 'hour' ? hourComponents : dayComponents()}
                </div>
                <div className='cell-grid'>
                    {grid}
                </div>
            </div>
        );
    };
}

export default CalendarLine;