import React from 'react';
// @ts-ignore
import Clock from 'react-live-clock';
import {actionCreators as routerActions} from '../store/RouterActions';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Link} from "react-router-dom";
import mapImage from '../images/map.png';
import resourceImage from '../images/udstyr.png';
import eventsImage from '../images/events.png';

import './Menu.css';

interface Props{
    logoImage?: string;
}

const Menu = (props: Props) => {
    return(
        <div className="menu">
             <Clock format={'HH:mm'} ticking={true} timezone={'Europe/Copenhagen'} interval={30000}/>
            <div className="menu-point-container">
                <div className="menu-point"><Link to={"/"}><img src={require('../images/home.png')} alt='Hjem menu'/></Link></div>
                <div className="menu-point"><Link to={"/plan"}><img src={require('../images/plan.png')} alt='Plan menu'/></Link></div>
                <div className="menu-point"><Link to={"/documents"}><img src={require('../images/documents.png')} alt='Arkiv menu'/></Link></div>
                <div className="menu-point"><Link to={'/map'}><img src={mapImage} alt='Map menu' /></Link></div>
                <div className="menu-point"><Link to={'/resource'}><img src={resourceImage} alt='Resource menu' /></Link></div>
                <div className="menu-point"><Link to={'/events'}><img src={eventsImage} alt='events menu' /></Link></div>
                {props.logoImage && <img style={{position: 'absolute', bottom: 0, left: 0}} src={props.logoImage} alt='' />}
            </div>
        </div>
    );
};

export default connect(
    state => state,
    dispatch => ({
        routerActions: bindActionCreators(routerActions, dispatch)
    })
)(Menu);