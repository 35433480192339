import React from 'react';
import Slider from 'react-slick';
import connect from 'react-redux/es/connect/connect';
import PropTypes from 'prop-types';
import {apiRootUrl} from "../../store/configureStore";

const SlideShow = props => {

    const slideShow = props.state.slideState.slideShows[props.slideShowId];
    const settings = {
        dots: false,
        autoplay: true,
        swipeToSlide: true,
        autoplaySpeed: 8000,
        arrows: false
    };

    const renderSlide = (slide) => {
        return (
            <div key={slide.id} className="slide">
                <img src={apiRootUrl + slide.imagePath} alt=''/>
            </div>
        );
    };
    const slideComponents = slideShow && slideShow.slides.map((s) => renderSlide(s));

    return (
        <div className={`temp-slide ${props.className || ''}`}>
            <Slider {...settings}>
                {slideComponents}
            </Slider>
        </div>
    );
};

SlideShow.propTypes = {
    slideShowId: PropTypes.number.isRequired,
    className: PropTypes.string,
};
export default connect(
    state => ({state: state})
)(SlideShow);