import React from 'react';
import TeamOverview from './TeamOverview'
import './TeamList.css';
import ColorLegend from '../planning/ColorLegend';
import {Team} from "../../domain/Team";


interface Props{
    teams?: Team[],
}

const TeamList = (props: Props) =>{
    const teams = props.teams;
    if(!teams) return null;
    const width = 100/teams.length;

    const tc = teams.map((team) => {
        return <TeamOverview key={team.id} team={team} width={width} />
    });

    return(
        <div className="team-list">
            {tc}
            <ColorLegend />
        </div>
    )

};

export default TeamList;