import React from 'react';
import {apiRootUrl} from "../../store/configureStore";
import {Person} from "../../domain/Person";

interface Props{
    person?: Person;
    onClick: (pId: string) => void;
}

const BookingPerson = (props: Props) => {
    const p = props.person;
    if(!p) return null;

    return(
        <div className='person' onClick={() => props.onClick(p.id)}>
            <img src={`${apiRootUrl}/${p.imagePath}`} alt='PersonView billede' />
            <div className='person-name'>{p.name}</div>
        </div>
    )
};

export default BookingPerson;