import React from 'react';
import PersonView from './PersonView';
import '../../css/Team.css';
import {connect} from 'react-redux';
import {AppState} from "../../store/configureStore";
import {PersonState} from "../../store/PersonStore";
import {Team} from "../../domain/Team";
import {Person} from "../../domain/Person";

interface Props{
    personState: PersonState
    team: Team;
    subTeams?: Array<Team|undefined>;
    width: number;
}

const TeamOverview = (props: Props) => {
    const team = props.team;
    const members = team.members;

    const renderPersonComponents = (members: Person[]) => {
        return members.map((member, i) => {
            const p = props.personState.persons[member.id];
            return <PersonView key={i} person={p} />
        });
    };

    let teamLeader;
    if(team.teamLeader){
        const p = props.personState.persons[team.teamLeader.id];
        teamLeader = <PersonView person={p}/>;
    }
    const style = {
        width: props.width + '%'
    };

    const renderSubTeams = () => {
        if(!props.subTeams) return;
        return props.subTeams.map((x,i) => {
            if(!x) return null;
            return(
                <div key={i} className='subteam'>
                    <div className="divider" />
                    <h4 className="team-description">{x.name}</h4>
                    <div className="members">
                        {renderPersonComponents(x.members)}
                    </div>
                </div>
            )
        })
    };

    return (
        <div className="team" style={style}>
            <div className="inner">
                <h3 className="team-name">{team.name}</h3>
                <div className="divider" />
                <h4 className="team-description">{team.description}</h4>
                <div className="teamleader">{teamLeader}</div>
                <div className="members">
                    {renderPersonComponents(members)}
                </div>
                {renderSubTeams()}
            </div>
        </div>
    );
};

export default connect(
    (state: AppState) => ({personState: state.personState}),
)(TeamOverview);
