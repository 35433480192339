import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as EventStore from '../../store/EventStore';
import * as PersonStore from '../../store/PersonStore';
import React from 'react';
import eventAcceptImage from '../../images/event_accept.png';
import eventRejectImage from '../../images/event_reject.png';
import {apiRootUrl} from "../../store/configureStore";

const Invitee = props => {
    const personsMap = props.personState.persons;
    const p = personsMap[props.eventInvitation.personId];
    if(!p) return null;

    const renderResponseForm = () => {
        return(
            <div className='invitee-menu'>
                <img className='accept-image' src={eventAcceptImage} alt=''/>
                <div className='accept-text' onClick={() => props.eventActions.acceptInvite(props.eventInvitation.id)}>
                    Tilmeld
                </div>
                <img className='reject-image' src={eventRejectImage} alt=''/>
                <div className='reject-text' onClick={() => props.eventActions.rejectInvite(props.eventInvitation.id)}>
                    Afslå
                </div>
            </div>
        )
    };

    const showInviteeMenu = props.eventState.showInviteeMenu === p.id;

    return(
        <div className='invitee' onClick={() => props.eventActions.showInviteeMenu(!showInviteeMenu, p.id)}>
            <img src={`${apiRootUrl}/${p.imagePath}`} alt='PersonView billede' />
            <div className='invitee-name'>{p.name}</div>
            { showInviteeMenu && renderResponseForm() }
        </div>
    )
};

export default connect(
    state => ({ personState: state[PersonStore.reducerName], eventState: state[EventStore.reducerName]}),
    dispatch => ({ eventActions: bindActionCreators(EventStore.actionCreators, dispatch)})
)(Invitee);