import React from 'react';
import connect from 'react-redux/es/connect/connect';
import {bindActionCreators} from 'redux';
import * as ResourceStore from '../../store/ResourceStore';
import Unit from './Unit';
import ResourceCalendar from './ResourceCalendar';
import moment from 'moment';
import './Resource.css';
import TeamChooser from './TeamChooser';
import TimeSelectForm from "./TimeSelectForm";


const Resource = props => {
    const now = moment();
    const units = props.resState.units;
    const bookings = props.resState.bookings;

    const bookingsNow = bookings.filter(x => now.isSameOrAfter(x.startTime) && now.isSameOrBefore(x.endTime));

    const futureBookings = bookings.filter(x => now.isBefore(x.startTime))
        .sort((a,b) => {
            a = new Date(a.startTime);
            b = new Date(b.startTime);
            return a < b ? -1 : a > b ? 1 : 0
        });

    return(
        <div className='Resource'>
            <div className='padding-content'>
                <div className='units-container'>
                    <h2>Book udstyr</h2>
                    <div  className='units'>
                        {
                            units.map((u,i) => {
                                const nextBooking = futureBookings.find(x => x.unitId === u.id);
                                const currentBooking = bookingsNow.find(x => x.unitId === u.id);
                                return(
                                    <Unit
                                        key={i}
                                        unit={u}
                                        currentBooking={currentBooking}
                                        nextBooking={nextBooking}
                                    />
                                )
                            })
                        }
                    </div>
                    <div className='clearfix'/>
                </div>
                <ResourceCalendar/>
            </div>
            <TimeSelectForm/>
            <TeamChooser/>
        </div>
    )
};

export default connect(
    state => ({resState: state[ResourceStore.reducerName]}),
    dispatch => ({
        resourceActions: bindActionCreators(ResourceStore.actionCreators, dispatch),
    })
)(Resource);