import React from 'react';
import MapImage from '../images/vallensbaek_map.png';
import './StaticMap.css';

export const StaticMap = props => {
    return(
        <div className='static-map'>
            <img src={MapImage} alt=""/>
        </div>
    )
};