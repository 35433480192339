import axios from 'axios';
import { createAuth } from '../Helpers';
import {apiRootUrl, ReducerMethods, TA} from "./configureStore";
import {Reducer} from "redux";
import {DDEvent} from "../domain/DDEvent";

export const reducerName = 'eventState';

const EVENTS_GET_FUTURE_REQUEST = 'EVENTS_GET_FUTURE_REQUEST';
const EVENTS_GET_FUTURE_RESPONSE = 'EVENTS_GET_FUTURE_RESPONSE';

const EVENTS_SHOW_INVITEES = 'EVENTS_SHOW_INVITEES';
const EVENTS_SHOW_INVITEE_MENU = 'EVENTS_SHOW_INVITEE_MENU';

const EVENTS_ACCEPT_INVITE = 'EVENTS_ACCEPT_INVITE';
const EVENTS_REJECT_INVITE = 'EVENTS_REJECT_INVITE';

export interface EventState {
    events: DDEvent[];
    showInviteesForEvent?: boolean;
    showInviteeMenu?: boolean;
}

const initialState: EventState = {
    events: [],
    showInviteesForEvent: false,
    showInviteeMenu: false
};

export const actionCreators = {
    getFutureEvents: (): TA => async(dispatch, getState) => {
        dispatch({type: EVENTS_GET_FUTURE_REQUEST});

        axios({
            url: `${apiRootUrl}/api/event`,
            method: 'GET',
            withCredentials: true,
            auth: createAuth(getState)
        }).then(response => {
            dispatch({type: EVENTS_GET_FUTURE_RESPONSE, data: response.data});
        }).catch(error => {
            console.log(error);
        })
    },
    showInviteesForEvent: (show: boolean, eventId: number): TA => async(dispatch, getState) => {
        dispatch({type: EVENTS_SHOW_INVITEES, show, eventId});
    },
    showInviteeMenu: (show: boolean, personId: string): TA => async(dispatch, getState) => {
        dispatch({type: EVENTS_SHOW_INVITEE_MENU, show, personId});
    },
    acceptInvite: (invitationId: number): TA => async(dispatch, getState) => {
        dispatch({type: EVENTS_ACCEPT_INVITE, invitationId});

        axios({
            url: `${apiRootUrl}/api/event/${invitationId}/accept`,
            method: 'POST',
            withCredentials: true,
            auth: createAuth(getState)
        }).then(response => {
            dispatch({type: EVENTS_GET_FUTURE_RESPONSE, data: response.data});
        });
    },
    rejectInvite: (invitationId: number): TA => async(dispatch, getState) => {
        dispatch({type: EVENTS_REJECT_INVITE, invitationId});

        axios({
            url: `${apiRootUrl}/api/event/${invitationId}/reject`,
            method: 'POST',
            withCredentials: true,
            auth: createAuth(getState)
        }).then(response => {
            dispatch({type: EVENTS_GET_FUTURE_RESPONSE, data: response.data});
        });
    }
};

const reducerMethods: ReducerMethods<EventState> = {
    EVENTS_GET_FUTURE_RESPONSE: (state, action) => {
        return{
            ...state,
            events: action.data,
        }
    },
    EVENTS_SHOW_INVITEES: (state, action) => {
        return{
            ...state,
            showInviteesForEvent: action.show ? action.eventId : false
        }
    },
    EVENTS_SHOW_INVITEE_MENU: (state, action) => {
        return{
            ...state,
            showInviteeMenu: action.show ? action.personId : false
        }
    }
};

export const reducer: Reducer<EventState> = (state, action) => {
    state = state || initialState;
    const method = reducerMethods[action.type];
    if (method) return method(state, action);
    return state;
};
