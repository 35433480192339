import React from 'react';
import {bindActionCreators} from 'redux';
import * as ResourceStore from '../../store/ResourceStore';
import * as TeamStore from '../../store/TeamStore';
import * as PersonStore from '../../store/PersonStore';
import Unit from './Unit';
import BookingPerson from "./BookingPerson";
import {ResourceState} from "../../store/ResourceStore";
import {TeamState} from "../../store/TeamStore";
import {PersonState} from "../../store/PersonStore";
import {Team} from "../../domain/Team";
import {AppState} from "../../store/configureStore";
import {connect} from "react-redux";

interface Props{
    resState: ResourceState;
    teamState: TeamState;
    personState: PersonState;
    resourceActions: typeof ResourceStore.actionCreators;
}

const TeamChooser = (props: Props) => {
    const showMenu = props.resState.showTeamMenu;
    if(!showMenu) return null;

    const units = props.resState.units;
    const teams = props.teamState.teams;
    const personsMap = props.personState.persons;
    const bookingPerson = props.resState.createBooking.personId ? personsMap[props.resState.createBooking.personId] : undefined;


    const unitBooking = units.find(x => x.id === props.resState.createBooking.unitId);
    const createBooking = props.resState.createBooking;

    const renderPersonChooser = (team?: Team) => {
        if(!team) return null;
        return(
            <div className='dialog-overlay' onClick={() => props.resourceActions.setBookingField('teamId', 0)}>
                <div className='dialog' onClick={(e) => e.stopPropagation()}>
                    <h1>Vælg en person</h1>
                    <div className='persons'>
                        <BookingPerson person={team.teamLeader}  onClick={(pId: string) => props.resourceActions.setBookingField('personId', pId)} />
                        {team.members.map(x =>
                            <BookingPerson key={x.id} person={x} onClick={(pId: string) => props.resourceActions.setBookingField('personId', pId)}  />
                        )}
                    </div>
                </div>
            </div>
        );
    };

    const renderTeam = (team: Team) => {
        return(
            <div key={team.id} className={`team${createBooking.teamId === team.id ? '' : ' active'}`} onClick={
                () => {
                    props.resourceActions.setBookingField('teamId', team.id);
                    props.resourceActions.setBookingField('personId', '');
                }}
            >
                <div className='text'>
                    <div>{team.name}</div>
                    <div className='text-muted text-sm'>{team.description}</div>
                </div>
            </div>
        )
    };

    /*const renderRow = (mainTeam: Team, teams?: Array<Team>) => {
        if(mainTeam === null){
            return(
                <div className='team-row'>
                    {teams && teams.map(x => renderTeam(x))}
                </div>
            )
        }
        return(
            <div className='team-row'>
                <h2>{mainTeam.name}</h2>
                {renderTeam(mainTeam)}
                {teams && teams.map(x => renderTeam(x))}
            </div>
        )
    };*/

    if(props.resState.createBooking.teamId && !props.resState.createBooking.personId){
        return renderPersonChooser(teams.find(x => x.id === props.resState.createBooking.teamId));
    }

    return(
        <div className='dialog-overlay' onClick={() => props.resourceActions.showTeamMenu(false)}>
            <div className='dialog teamchooser' onClick={(e) => e.stopPropagation()}>
                <div className='unit-container'>
                    <Unit unit={unitBooking} hideText noMenu hideName/>
                    <div className='clearfix'/>
                </div>
                <h1>{bookingPerson ? bookingPerson.name : 'Hvem'} reserverer "{unitBooking && unitBooking.name}"?</h1>

                <div className='team-row'>
                    {props.teamState.teams.map(x => renderTeam(x))}
                </div>

                <div className={`dialog-btn${createBooking.teamId ? '' : ' disabled'}`} onClick={() => {
                        props.resourceActions.showTimeMenu(false);
                        props.resourceActions.showTeamMenu(true);
                        props.resourceActions.setTeamAndBook(createBooking.teamId, createBooking.personId || '');
                    }}
                >Done</div>
            </div>
        </div>
    )
};

export default connect(
    (state: AppState) => ({
        teamState: state[TeamStore.reducerName],
        resState: state[ResourceStore.reducerName],
        personState: state[PersonStore.reducerName]
    }),
    dispatch => ({
        resourceActions: bindActionCreators(ResourceStore.actionCreators, dispatch),
    })
)(TeamChooser);