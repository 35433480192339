import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import configureStore from "./ddc/src/store/configureStore";
import './index.css';
import App from "./App";
import {Router} from "react-router-dom";
import {createBrowserHistory} from "history";


export const history = createBrowserHistory();
const store = configureStore();

const rootElement = document.getElementById('root');

ReactDOM.render(
    <Provider store={store}>
        <Router history={history}>
            <App/>
        </Router>
    </Provider>,
    rootElement
);
