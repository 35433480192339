import React, {Component} from 'react';
import './Person.css'
import {bindActionCreators} from 'redux';
import {actionCreators as routerActions} from '../../store/RouterActions';
import {actionCreators as plannedItemActions, PlannedItemState} from '../../store/PlannedItemStore';
import {actionCreators as personActions} from '../../store/PersonStore';

import checkInInvertedImage from '../../images/radial_checkin_inversed.png';
import checkOutInvertedImage from '../../images/radial_checkout_inversed.png';
import planInvertedImage from '../../images/radial_plan_inversed.png';
import checkInImage from '../../images/radial_checkin.png';
import checkOutImage from '../../images/radial_checkout.png';
import radialPlanImage from '../../images/radial_plan.png';
import illImage from '../../images/radial_ill.png';
import illInvertedImage from '../../images/radial_ill_inversed.png';
import flagImage from '../../images/flag.png';
import starImage from '../../images/notification.png';
import moment from 'moment';
import {Person} from "../../domain/Person";
import {EventState} from "../../store/EventStore";
import {apiRootUrl, AppState} from "../../store/configureStore";
import {connect} from "react-redux";
import {history} from "../../../../index";

interface Props{
    person: Person;
    personActions: typeof personActions;
    plannedItemActions: typeof plannedItemActions;
    eventState: EventState;
    plannedItemState: PlannedItemState;
}

class PersonView extends Component<Props> {
    private element: React.RefObject<HTMLDivElement>;

    constructor(props: Props) {
        super(props);
        this.element = React.createRef();
    }

    render(){
        const props = this.props;
        const getToday = () => {
            return moment();
        };
        const person = props.person;
        if(!person) return null;

        const unansweredEvent = props.eventState.events.filter(x => x.invitations.find(y => y.status === 0 && y.personId === person.id) != null)

        const date = getToday();
        const itemsToday = props.plannedItemState.plans
            .filter(x =>
                x.personId === person.id &&
                date.isSameOrAfter(x.startTime, 'day') &&
                date.isSameOrBefore(x.endTime)
            );
        const item = itemsToday.find(x => date.isAfter(x.startTime) && date.isBefore(x.endTime));
        const colorCodes = props.plannedItemState.colorCodes;
        const checkColor = colorCodes && colorCodes.find(x => x.systemName === 'checked');
        const uncheckColor = colorCodes && colorCodes.find(x => x.systemName === 'unchecked');
        const sickColor = colorCodes && colorCodes.find(x => x.systemName === "sick");

        const RadialMenu = () => {
            if(!person.showRadial || this.element === undefined) return null;

            const checkIn = () => {
                props.personActions.checkIn(person.id);
            };
            const checkOut = () => {
                props.personActions.checkOut(person.id);
            };
            const plan = () => {
                history.push(`/plan/${person.id}`);
                props.plannedItemActions.showPlanForm(true);
            };
            const planSick = () => {
                const start = moment().set('hour', 7).set('minutes', 0);;
                const end = moment().set('hour', 15).set('minutes', 0);

                sickColor &&
                props.plannedItemActions.createPlannedItem(
                    person.id,
                    sickColor.id,
                    start,
                    end
                );
            };

            const pct = this.element.current && this.element.current.getBoundingClientRect().left / document.documentElement.clientWidth;
            const inverse = (pct || 0) > 0.5;

            return (
                <div className={`radial${inverse ? ' inversed' : ''}`}>
                    <div className="checkin">
                        <img src={inverse ? checkInInvertedImage : checkInImage} alt="checkin"/>
                        <button onClick={checkIn}>
                            {checkColor && checkColor.name}
                        </button>
                    </div>
                    <div className="checkout">
                        <img src={inverse ? checkOutInvertedImage : checkOutImage} alt="checkout"/>
                        <button onClick={checkOut}>
                            {uncheckColor && uncheckColor.name}
                        </button>
                    </div>

                    <div className="plan">
                        <img src={inverse ? planInvertedImage : radialPlanImage} alt="plan"/>
                        <button onClick={plan}>
                            Planlæg
                        </button>
                    </div>

                    <div className="ill">
                        <img src={inverse ? illInvertedImage : illImage}  alt='illness'/>
                        <button onClick={planSick}>
                            Syg i dag
                        </button>
                    </div>
                </div>
            );
        };

        const toggleRadialMenu = () => {
            props.personActions.showRadial(person.id, !!(!person.showRadial));
        };

        let className = 'person clearfix';
        let style;

        if(person.status === 'checked'){
            className += ' circle checked';
            style = { borderColor:`${checkColor && checkColor.hexColor}`, borderRadius: '50%', borderStyle: 'solid'};
        }
        if(person.status === 'unchecked'){
            className += ' circle unchecked';
            style = { borderColor:`${uncheckColor && uncheckColor.hexColor}`, borderRadius: '50%', borderStyle: 'solid'};
        }
        className = person.showRadial ? className + ' circle radial' : className;

        if(item){
            className += ' circle';
            style = { borderColor:`${item.colorCode.hexColor}`, borderRadius: '50%', borderStyle: 'solid'};
        }

        const isLoading = false;
        const profileImageClass = isLoading ? 'profile-img loading' : 'profile-img';

        const radial = RadialMenu();
        const birthDate = person.birthDate ? moment(person.birthDate) : null;
        const isB = birthDate && birthDate.isSame(moment().year(birthDate.year()), 'd');
        const celebrateComp = isB ? <img className="birthday" src={flagImage} alt=''/> : '';

        return (
            <div className={className} onClick={() => toggleRadialMenu()} ref={this.element}>
                <div className="profile clearfix" >
                    <img className={profileImageClass} src={apiRootUrl + person.imagePath} alt="" style={style ? style : {}}/>
                    {unansweredEvent.length > 0 && <div className='profile-img-star'><img src={starImage} alt={'event_invite'}/><span className='event-count'>{unansweredEvent.length}</span></div> }
                    {celebrateComp}
                    {radial}
                </div>
                <div className="flytext">{person.name}</div>
                <div className="flytext-title">{person.title}</div>
                <div className="flytext-plans">
                    {itemsToday &&
                        itemsToday.map((x,i) => {
                                if(item && x.id === item.id) return null;
                                return(
                                    <div key={i} className='plan'>
                                        {moment(x.startTime).format('HH:mm')}
                                        <span style={{backgroundColor: x.colorCode.hexColor}} className='dot'/>
                                    </div>
                                )
                            }
                        )
                    }
                </div>
            </div>
        );
    }
}

export default connect(
    (state: AppState) => ({
        eventState: state.eventState,
        plannedItemState: state.plannedItemState,
    }),
    dispatch => ({
        routerActions: bindActionCreators(routerActions, dispatch),
        personActions: bindActionCreators(personActions, dispatch),
        plannedItemActions: bindActionCreators(plannedItemActions, dispatch)
    })
)(PersonView);


