import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom';
import './ddc/src/css/App.css';
import './App.css';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import IdleTimer from 'react-idle-timer';
import {history} from "./index";
import * as TeamStore from './ddc/src/store/TeamStore';
import * as PersonStore from "./ddc/src/store/PersonStore";
import * as PlannedItemStore from "./ddc/src/store/PlannedItemStore";
import * as DocumentStore from "./ddc/src/store/DocumentStore";
import * as ResourceStore from "./ddc/src/store/ResourceStore";
import * as EventStore from "./ddc/src/store/EventStore";
import * as DrStore from "./ddc/src/store/DrNewsStore";
import * as AppStore from "./ddc/src/store/AppStore";
import {Calendar, Documents, Menu, TeamList} from "./ddc/src";
import {AppState} from "./ddc/src/store/configureStore";
import DrNews from "./ddc/src/components/slides/DrNews";
import PlanForm from "./ddc/src/components/planning/PlanForm";
import logoImage from './images/val_skjold.png';
import EventOverview from "./ddc/src/components/event/EventOverview";
import Resource from "./ddc/src/components/resource/Resource";
import Login from "./ddc/src/components/Login";
import SlideShow from "./ddc/src/components/slides/SlideShow";
import {StaticMap} from "./ddc/src/components/StaticMap";
import FreeText from './ddc/src/components/freeText/FreeText';
import flatpickr from 'flatpickr';
import {Danish} from 'flatpickr/dist/l10n/da';

const shortTime = 1000*60*2;
const longTime = 1000*60*15;

interface Props{
    teamActions: typeof TeamStore.actionCreators;
    personActions: typeof PersonStore.actionCreators;
    plannedItemActions: typeof PlannedItemStore.actionCreators;
    documentActions: typeof DocumentStore.actionCreators;
    eventActions: typeof EventStore.actionCreators;
    resourceActions: typeof ResourceStore.actionCreators;
    drActions: typeof DrStore.actionCreators;
    teamState: TeamStore.TeamState;
    appActions: typeof AppStore.actionCreators;
}

flatpickr.localize(Danish);

class App extends Component<Props>{

    componentDidMount(): void {
        const props = this.props;
        const refreshQuick = () => {
            props.appActions.refresh();
        };
        const refreshSlow = () => {
            props.appActions.refreshSlow();
        };

        setInterval(refreshQuick, shortTime);
        setInterval(refreshSlow, longTime);

        refreshQuick();
        refreshSlow();
    }

    render(){
        return (
            <div className="App">
                <Menu logoImage={logoImage} />
                <div>
                    <Switch>
                        <Route
                            exact
                            path="/"
                            render={
                                (props) => {
                                    return(
                                        <div className='vallensbaek'>
                                            <TeamList teams={this.props.teamState.teams} />
                                            <div className='news-slider'>
                                                <DrNews/>
                                            </div>
                                            <div className='free-text-item'>
                                                <FreeText />
                                            </div>
                                            <div className='slider-2'>
                                                <SlideShow slideShowId={10015} />
                                            </div>
                                        </div>
                                    )
                                }
                            }
                        />
                        <Route exact path="/plan/:personId?" render={
                            (props) => {
                                const pId = props.match.params.personId;
                                return(
                                    <div>
                                        <Calendar showOnlySickToday={true} />
                                        {pId && <PlanForm personid={pId} />}
                                    </div>
                                )
                            }}
                        />
                        <Route exact path="/documents" render={() => <Documents />}/>
                        <Route exact path="/events" render={() => <EventOverview />} />
                        <Route exact path="/resource" render={() => <Resource />} />
                        <Route exact path="/map" render={() => <StaticMap />} />
                        <Route exact path="/login" component={Login} />
                    </Switch>
                    <IdleTimer
                        ref="idleTimer"
                        element={document}
                        onIdle={() => history.push("/")}
                        timeout={120000}
                    />
                </div>
            </div>
        );
    }
}


export default connect(
    (state: AppState) => ({teamState: state.teamState}),
    dispatch => ({
        teamActions: bindActionCreators(TeamStore.actionCreators, dispatch),
        personActions: bindActionCreators(PersonStore.actionCreators, dispatch),
        plannedItemActions: bindActionCreators(PlannedItemStore.actionCreators, dispatch),
        documentActions: bindActionCreators(DocumentStore.actionCreators, dispatch),
        eventActions: bindActionCreators(EventStore.actionCreators, dispatch),
        resourceActions: bindActionCreators(ResourceStore.actionCreators, dispatch),
        drActions: bindActionCreators(DrStore.actionCreators, dispatch),
        appActions: bindActionCreators(AppStore.actionCreators, dispatch)
    })
)(App);