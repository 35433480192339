import axios from 'axios';
import {createAuth} from "../Helpers";
import {apiRootUrl, ReducerMethods, TA} from "./configureStore";
import {Reducer} from "redux";

export const reducerName = "drNewsState";

const DRNEWS_GET_RESPONSE = 'DRNEWS_GET_RESPONSE';

export interface DrNewsState{
    news: any[];
}

const initialState: DrNewsState = {
    news: []
};

export const actionCreators = {
    getNews: (): TA => async(dispatch, getState) => {
        axios({
            method: 'GET',
            url: `${apiRootUrl}/api/adminOptions/drrss`,
            withCredentials: true,
            auth: createAuth(getState)
        }).then(response => {
            const data = response.data.rss.channel.item;
            dispatch({type: DRNEWS_GET_RESPONSE, data: data});
        }).catch(error => {

        });
    }
};

const reducerMethods: ReducerMethods<DrNewsState> = {
    DRNEWS_GET_RESPONSE: (state, action) => {
        return{
            ...state,
            news: action.data
        }
    }
};

export const reducer: Reducer<DrNewsState> = (state, action) => {
    state = state || initialState;
    const method = reducerMethods[action.type];
    if (method) return method(state, action);
    return state;
};