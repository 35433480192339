import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as ResourceStore from '../../store/ResourceStore';
import * as TeamStore from '../../store/TeamStore';
import moment from 'moment';
import Flatpickr from 'react-flatpickr';
import Unit from './Unit';

const TimeSelectForm = props => {
    const showMenu = props.resState.showTimeMenu;
    if(!showMenu) return null;

    const units = props.resState.units;
    const unitBooking = units.find(x => x.id === props.resState.createBooking.unitId);
    const model = props.resState.createBooking;

    return(
        <div className='dialog-overlay' onClick={() => props.resourceActions.showTimeMenu(false)}>
            <div className='dialog timechooser' onClick={(e) => e.stopPropagation()}>
                <div className='unit-container'>
                    <Unit unit={unitBooking} hideText noMenu hideName/>
                    <div className='clearfix'/>
                </div>
                <h1>Hvornår skal "{unitBooking.name}" reserveres?</h1>
                <div className='container'>
                    <div className="form-group date">
                        <label>Start</label>
                        <Flatpickr
                            options={{time_24hr: true, enableTime: true, minuteIncrement: 30}}
                            onChange={d => props.resourceActions.setBookingField('startTime', moment(d[0]))} value={moment(model.startTime).format('YYYY-MM-DD HH:mm')}
                            className={'date-picker-custom'}
                        />
                    </div>
                    <div className="form-group date">
                        <label>Slut</label>
                        <Flatpickr
                            options={{time_24hr: true, enableTime: true, minuteIncrement: 30}}
                            onChange={d => props.resourceActions.setBookingField('endTime', moment(d[0]))} value={moment(model.endTime).format('YYYY-MM-DD HH:mm')}
                            className={'date-picker-custom'}
                        />
                    </div>
                </div>
                <div className='clearfix'/>
                {props.resState.doubleBook &&
                    <div className='error'>Denne tidsperiode, eller dele af denne tidsperiode er desværre allerede booket.</div>
                }
                <div className='dialog-btn' onClick={() => {props.resourceActions.showTimeMenu(false); props.resourceActions.showTeamMenu(true);}}>Videre</div>
            </div>
        </div>
    )
};

export default connect(
    state => ({
        teamState: state[TeamStore.reducerName],
        resState: state[ResourceStore.reducerName]
    }),
    dispatch => ({
        resourceActions: bindActionCreators(ResourceStore.actionCreators, dispatch),
    })
)(TimeSelectForm);